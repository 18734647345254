import logo from './logo.svg';
import './App.css';
import { Loader } from "@googlemaps/js-api-loader"
import { useEffect, useState } from 'react';

const loader = new Loader({
  apiKey: "AIzaSyDsqPN_EtlZd5Cu0oUkWCrFqc3ACEq19YE",
  version: "weekly",
  libraries: ["geometry"]
});

const relativeDistance = 3400;


function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [pinInput, setPinInput] = useState('');
  const [pinError, setPinError] = useState('');
  const [teams, setTeams] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [routePath, setRoutePath] = useState(null);
  const [teamPositions, setTeamPositions] = useState({});
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);

  async function loadMap() {
    try {
      await loader.load()
      const { Map } = await window.google.maps.importLibrary("maps"); // eslint-disable-line
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
      const mapInstance = new Map(document.getElementById("map"), {
        center: { lat: 40.1894837, lng: 44.5111702 },
        zoom: 7,
        mapId: "krisp_step_challenge",
        disableDefaultUI: true, // This will hide all default UI controls
      });
      setMapInstance(mapInstance);
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        preserveViewport: true
      });
      directionsRenderer.setMap(mapInstance);
      directionsService.route({ 
        origin: 'Yerevan, 4, 1 Marshal Baghramyan Avenue',
        destination: 'Santa Claus Village, Finland',
        waypoints: [
          {
            location: 'Saint Petersburg, Russia',
            stopover: true
          }
        ],
        travelMode: 'WALKING' 
      }, (result, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(result);
          // Calculate total route distance in kilometers
          const routeDistanceInMeters = result.routes[0].legs.reduce((total, leg) => total + leg.distance.value, 0);
          const routeDistanceInKm = routeDistanceInMeters / 1000;
          const relativeFactor = routeDistanceInKm / relativeDistance;

          // Flatten all steps into a single path array
          const detailedPath = result.routes[0].legs.flatMap(leg =>
            leg.steps.flatMap(step => step.path)
          );

          // Sort teams by result in descending order
          const sortedTeams = [...teams].sort((a, b) => (parseFloat(b.miles) * 1.60934) - (parseFloat(a.miles) * 1.60934));

          for (const [i, team] of sortedTeams.entries()) {
            const distance = team.result * 1000 * relativeFactor
            const point = getPointAtDistance(detailedPath, distance);
            if (point) {
              if (i === 0) {
                mapInstance.panTo({
                  lat: point.lat(),
                  lng: point.lng()
                });
                mapInstance.setOptions({ animation: window.google.maps.Animation.SMOOTH });
                setTimeout(() => {
                  mapInstance.setZoom(12)
                }, 1000)
              }
              const teamMarkerElement = document.createElement('div');
              const behind = sortedTeams[0].result - team.result;
              const behindText = behind ? `▼${behind.toFixed(1)}` : '';
              const progress = (team.result / relativeDistance * 100).toFixed(1);
              const rankColor = i === 0 ? '#FFD700' : i === 1 ? '#C0C0C0' : i === 2 ? '#CD7F32' : '#007AFF';

              teamMarkerElement.innerHTML = `
              <div style="
                padding: 10px;
                background-color: #ffffff;
                position: relative;
                font-size: 11px;
                border-radius: 8px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                min-width: 180px;
                border: 2px solid ${rankColor};
              ">
                <div style="
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  margin-bottom: 6px;
                ">
                  <div style="
                    background: ${rankColor};
                    color: white;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-weight: bold;
                    font-size: 12px;
                  ">${i + 1}</div>
                  <div style="flex-grow: 1; min-width: 0;">
                    <div style="font-weight: bold; color: #333; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: left; font-size: 11px;">${team.name}</div>
                    <div style="
                      display: flex;
                      align-items: center;
                      gap: 6px;
                      margin-top: 3px;
                      font-size: 10px;
                    ">
                      <span style="color: #666; font-weight: 600;">${team.result.toFixed(1)}km</span>
                      ${behindText ? `<span style="color: #ff4444; font-weight: 600; background: rgba(255,68,68,0.08); padding: 2px 6px; border-radius: 4px; display: inline-flex; align-items: center; gap: 2px; font-size: 9px;">${behindText}km</span>` : ''}
                    </div>
                  </div>
                </div>
                
                
                <div style="
                  position: absolute;
                  left: 50%;
                  top: 100%;
                  transform: translate(-50%, 0);
                  width: 0;
                  height: 0;
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;
                  border-top: 8px solid #ffffff;
                "></div>
              </div>
            `
              const marker = new AdvancedMarkerElement({
                position: point,
                map: mapInstance,
                content: teamMarkerElement
              });
              setTeamPositions(prev => ({
                ...prev,
                [team.name]: point
              }));
            }
          }
        }
      });
    } catch (error) {
      console.error('Error loading map:', error);
    }
  }



  function getPointAtDistance(path, targetDistance) {
    let totalDistance = 0;

    for (let i = 1; i < path.length; i++) {
      const segmentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
        path[i - 1],
        path[i]
      );

      totalDistance += segmentDistance;

      if (totalDistance >= targetDistance) {
        // Interpolate between the two points
        const overshootDistance = totalDistance - targetDistance;
        const fraction = 1 - overshootDistance / segmentDistance;

        return window.google.maps.geometry.spherical.interpolate(
          path[i - 1],
          path[i],
          fraction
        );
      }
    }

    return null;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://krisp-step-challenge.pages.dev/results');
        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (teams.length > 0) {
      loadMap();
    }
  }, [teams]);


  return (
    <div className="App">
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        width: isEditMode ? '500px' : '400px',
        padding: '16px',
        background: 'rgba(255, 255, 255, 0.4)',
        backdropFilter: 'blur(16px)',
        WebkitBackdropFilter: 'blur(16px)',
        borderRadius: '12px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 40px)',
        boxSizing: 'border-box',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        transition: 'width 0.3s ease'
      }}>
        <div 
          onMouseEnter={() => setIsHeaderHovered(true)}
          onMouseLeave={() => setIsHeaderHovered(false)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px'
          }}>
          <h2 style={{
            margin: '0',
            fontSize: '20px',
            fontWeight: '600',
            color: '#1a1a1a'
          }}>Leaderboard</h2>
          <button
            onClick={() => {
              if (isEditMode) {
                setIsEditMode(false);
                setPinInput('');
                setPinError('');
              } else {
                setFormValues(teams);
                setIsEditMode(true);
              }
            }}
            style={{
              padding: '8px 16px',
              border: 'none',
              borderRadius: '6px',
              background: isEditMode ? '#ff4444' : '#007AFF',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '500',
              opacity: isHeaderHovered || isEditMode ? 1 : 0,
              transition: 'opacity 0.2s ease',
              pointerEvents: isHeaderHovered || isEditMode ? 'auto' : 'none'
            }}
          >
            {isEditMode ? 'Cancel' : 'Edit Results'}
          </button>
        </div>
        {isEditMode ? (
          <form onSubmit={async (e) => {
            e.preventDefault();
            try {
              const response = await fetch('https://krisp-step-challenge.pages.dev/update', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  results: formValues,
                  pin: pinInput
                })
              });

              const data = await response.json();
              
              if (!response.ok) {
                setPinError(data.error || 'Failed to update results');
                return;
              }

              setTeams(formValues);
              await loadMap();
              setIsEditMode(false);
              setPinInput('');
              setPinError('');
            } catch (error) {
              setPinError('Failed to update results. Please try again.');
            }
          }}>
            {formValues.map((team, index) => (
              <div key={team.name} style={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px',
                marginBottom: '8px',
                background: '#fff',
                borderRadius: '8px',
                border: '1px solid #e0e0e0'
              }}>
                <div style={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '50%',
                  backgroundColor: '#007AFF',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '14px',
                  marginRight: '12px'
                }}>{index + 1}</div>
                
                <div style={{ 
                  flex: 1,
                  fontSize: '14px',
                  fontWeight: '500',
                  textAlign: 'left'
                }}>{team.name}</div>

                <input
                  style={{
                    width: '70px',
                    padding: '6px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    fontSize: '14px',
                    textAlign: 'center'
                  }}
                  type="text"
                  placeholder="0.0"
                  value={team.miles || ''}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^\d.]/g, '');
                    const newValues = [...formValues];
                    newValues[index] = {
                      ...team,
                      miles: value,
                      result: (parseFloat(value) || 0) * 1.60934
                    };
                    setFormValues(newValues);
                  }}
                />
                <span style={{ 
                  marginLeft: '8px',
                  fontSize: '13px',
                  color: '#666',
                  width: '80px'
                }}>
                  {team.result.toFixed(1)} km
                </span>
              </div>
            ))}
            <div style={{ 
              marginTop: '20px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center'
            }}>
              <input
                type="password"
                maxLength={6}
                placeholder="Enter PIN"
                value={pinInput}
                onChange={(e) => {
                  setPinError('');
                  setPinInput(e.target.value.replace(/[^0-9]/g, ''));
                }}
                style={{
                  flex: 1,
                  padding: '8px',
                  fontSize: '14px',
                  textAlign: 'center',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px'
                }}
              />
              <button
                type="submit"
                style={{
                  padding: '8px 16px',
                  border: 'none',
                  borderRadius: '4px',
                  background: '#007AFF',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >Save</button>
            </div>
            {pinError && <p style={{ color: 'red', margin: '8px 0', fontSize: '13px' }}>{pinError}</p>}
          </form>
        ) : (
          [...teams].sort((a, b) => b.result - a.result).map((team, index) => {
          const progress = (team.result / relativeDistance * 100).toFixed(1);
          const behind = index > 0 ? teams[0].result - team.result : 0;
          const rankColor = index === 0 ? '#FFD700' : index === 1 ? '#C0C0C0' : index === 2 ? '#CD7F32' : '#007AFF';
          
          return (
            <div 
              key={team.name} 
              onClick={() => {
                const position = teamPositions[team.name];
                if (position && mapInstance) {
                  mapInstance.setOptions({ animation: window.google.maps.Animation.SMOOTH });
                  mapInstance.panTo({
                    lat: position.lat(),
                    lng: position.lng()
                  });
                  setTimeout(() => {
                    mapInstance.setZoom(12);
                  }, 1000);
                }
              }}
              style={{
                padding: '10px',
                marginBottom: '8px',
                borderRadius: '8px',
                border: `1px solid ${rankColor}`,
                background: '#fff',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                ':hover': {
                  transform: 'scale(1.02)'
                }
              }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}>
                <div style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: rankColor,
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13px',
                  fontWeight: 'bold'
                }}>{index + 1}</div>
                <div style={{ flex: 1 }}>
                  <div style={{ fontSize: '14px', fontWeight: '600', textAlign: 'left' }}>{team.name}</div>
                  <div style={{ fontSize: '12px', color: '#666', display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <span>{team.result.toFixed(1)}km</span>
                    {behind > 0 && (
                      <span style={{ color: '#ff4444', fontSize: '11px', background: 'rgba(255,68,68,0.08)', padding: '2px 6px', borderRadius: '4px' }}>▼{behind.toFixed(1)}km</span>
                    )}
                  </div>
                </div>
                <div style={{ fontSize: '12px', color: '#666', textAlign: 'right' }}>
                  {progress}%
                </div>
              </div>
              <div style={{ background: '#f5f5f5', borderRadius: '4px', height: '6px', marginTop: '6px', overflow: 'hidden' }}>
                <div style={{
                  background: `linear-gradient(90deg, ${rankColor}99 0%, ${rankColor} 100%)`,
                  width: `${progress}%`,
                  height: '100%',
                  borderRadius: '4px',
                  transition: 'width 0.3s ease',
                  boxShadow: `0 1px 2px ${rankColor}33`
                }}></div>
              </div>
            </div>
          );
        }))}
      </div>
      <div id="map" style={{ width: '100%', height: '100vh' }}>
      </div>
    </div >
  );
}

export default App;
